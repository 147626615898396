<template>
  <div class="relative">
    <canvas ref="chart" width="300" height="300"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'UtilChart',
  props: {
    chart: {},
    data: {},
    signet: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.setChart()
  },
  methods: {
    setChart() {
      let ticks = {}

      let correction = 0

      if (this.signet) {
        correction = 100
        ticks = {
          stepSize: 10,
          min: -40,
          max: 40,
          beginAtZero: false,
          callback: function (value) {
            return value + correction
          }
        }
      } else {
        correction = 50
        ticks = {
          reverse: true,
          stepSize: 10,
          min: -50,
          max: 50,
          beginAtZero: true,
          callback: function (value) {
            return value + correction
          }
        }
      }

      let chart = {
        type: this.chart.type,
        responsive: true,
        maintainAspectRatio: false,
        data: {
          labels: this.data.labels,
          datasets: [
            {
              label: 'Data',
              data: this.data.data,
              borderWidth: 2,
              backgroundColor: '#3182ce'
            }
          ]
        },
        options: {
          tooltips: {
            callbacks: {
              label: t => {
                return t.xLabel + correction
              }
            }
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                position: 'top',
                ticks: ticks
              }
            ]
          }
        }
      }

      if (this.chart.type === 'radar') {
        delete chart.options.tooltips
        delete chart.options.scales
      }

      new Chart(this.$refs.chart.getContext('2d'), chart)
    }
  }
}
</script>

<style lang="scss" scoped></style>
