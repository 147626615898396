<template>
  <div class="border" :style="{ height: `${height}px`, 'bg-gray-800': loading }">
    <util-loader v-if="loading" :loading="loading" />
    <iframe v-show="show" @load="loadedIframe()" :src="src" class="w-full h-full border-0" />
  </div>
</template>

<script>
import UtilLoader from '@/components/legacy/UtilLoader'

export default {
  name: 'UtilIFrame',
  components: {
    UtilLoader
  },
  props: {
    src: {},
    chromeless: {
      type: Boolean,
      default: false
    },
    height: {
      default: 420
    }
  },
  data() {
    return {
      loading: true,
      show: false
    }
  },
  methods: {
    loadedIframe() {
      this.loading = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
