<template>
  <app-embed ref="embed">
    <util-loader :loading="animal.loading || animal.refreshing || animalIs.error">
      <template #loader>
        <img src="@/assets/legacy/silo.gif" class="w-full max-w-xs h-auto mx-auto block" />
      </template>
      <template #default>
        <div class="animal" ref="animal">
          <nav class="border mb-4 bg-gray-100 flex rounded">
            <a
              v-for="(item, i) in tabs"
              :key="`tab-${i}`"
              @click="setTab(item.slug)"
              class="animal-tabs-item"
              :class="{ active: tab === item.slug }"
            >
              <span class="animal-tabs-text">{{ item.text }}</span>
            </a>
          </nav>

          <div v-show="tab === 'chart'">
            <template v-if="horizontalBar">
              <util-chart
                key="horizontalBar"
                :signet="animalIs.signet"
                :chart="{ type: 'horizontalBar' }"
                :data="horizontalBar"
              />
            </template>
          </div>

          <div v-show="tab === 'area'">
            <template v-if="radar">
              <util-chart key="radar" :chart="{ type: 'radar' }" :data="radar" />
            </template>
          </div>

          <div v-show="tab === 'data'">
            <table v-if="animalIs.manual">
              <tbody>
                <tr v-for="(item, i) in animal.response.data.manual" :key="`item-${i}`">
                  <th>{{ i }}</th>
                  <td>{{ item }}</td>
                </tr>
              </tbody>
            </table>
            <table v-else>
              <thead>
                <tr>
                  <th></th>
                  <th>Ebv</th>
                  <th>Avg</th>
                  <th>Acc</th>
                  <th>Pct</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in animal.response.data.traits" :key="`item-${i}`">
                  <th>{{ item.title }}</th>
                  <td>{{ item.ebv }}</td>
                  <td>{{ item.avg }}</td>
                  <td>{{ item.acc }}</td>
                  <td>{{ item.pct ? parseInt(item.pct) : '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-show="tab === 'pedigree'">
            <util-iframe v-if="tab === 'pedigree'" :src="pedigree" :height="600" />
          </div>

          <util-button v-if="$route.query.debug" class="btn btn-block btn-gray mt-4" @click="getAnimal(true)">
            Refresh Data
          </util-button>
        </div>
      </template>
    </util-loader>
  </app-embed>
</template>

<script>
import AppEmbed from '@/components/legacy/AppEmbed'
import UtilChart from '@/components/legacy/UtilChart'
import UtilLoader from '@/components/legacy/UtilLoader'
import UtilIframe from '@/components/legacy/UtilIframe'
import UtilButton from '@/components/legacy/UtilButton'

export default {
  name: 'EmbedAnimal',

  components: {
    AppEmbed,
    UtilChart,
    UtilLoader,
    UtilIframe,
    UtilButton
  },

  data() {
    return {
      animal: this.$call(),
      tab: 'chart'
    }
  },

  created() {
    this.getAnimal()
  },

  mounted() {
    // eslint-disable-next-line
    _chatlio.hide()
  },

  computed: {
    animalIs() {
      let animal = this.animal
      let animalIs = {}
      animalIs.loaded = true
      animalIs.error = animal.response.status === 'error'

      if (animal.response.data) {
        animalIs.error = animal.response.data.status === 'DRAFT'
        if (animal.response.data.pedigree) {
          animalIs.signet = animal.response.data.pedigree.includes('signet')
        }
        if (animal.response.data.manual) {
          animalIs.manual = true
        }
      }
      return animalIs
    },

    horizontalBar() {
      let traits = this.animal.response.data.traits
      let correction = 50
      if (this.animalIs.signet) correction = 100
      if (traits) {
        traits = traits.filter(i => i.pct)
        return {
          labels: traits.map(i => i.title),
          data: traits.map(i => parseInt(i.pct) - correction)
        }
      } else {
        return false
      }
    },

    radar() {
      let traits = this.animal.response.data.traits
      if (traits) {
        traits = traits.filter(i => i.pct)
        return {
          labels: traits.map(i => i.title),
          data: traits.map(i => parseInt(i.pct))
        }
      } else {
        return false
      }
    },

    pedigree() {
      return this.animal.response.data.pedigree
    },

    tabs() {
      let tabs = [
        { icon: 'chart-bar', text: 'Chart', slug: 'chart', active: !this.animalIs.manual },
        { icon: 'chart-area', text: 'Area', slug: 'area', active: !this.animalIs.manual },
        { icon: 'table', text: 'Data', slug: 'data', active: true },
        { icon: 'tag', text: 'Pedigree', slug: 'pedigree', active: true }
      ]
      return tabs.filter(i => i.active)
    }
  },
  methods: {
    setTab(i = 0) {
      this.tab = i
      this.$nextTick(() => {
        this.$refs.embed.setHeight({
          width: this.$refs.animal.clientWidth,
          height: this.$refs.animal.clientHeight
        })
      })
    },

    async getAnimal(refresh = false) {
      this.animal.url = `/v1/animals/${this.$route.params.eartag}`
      this.animal.request.params = {
        path: this.$route.query.path,
        refresh: refresh
      }
      await this.$api(this.animal)
      if (this.animal.response.status === 'success') {
        if (this.animal.response.data.manual) {
          this.tab = 'data'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.animal {
  .animal-tabs-item {
    @apply text-center flex-1 text-gray-500 border-r px-3 py-2 block cursor-pointer;

    &.active {
      @apply bg-gray-200 text-gray-800;
    }

    &:last-child {
      @apply border-r-0;
    }

    .animal-tabs-text {
      @apply ml-1;
    }
  }

  table {
    @apply border-collapse w-full text-sm border-t;

    thead {
      @apply bg-gray-200;
    }

    tr {
    }

    th {
      @apply text-left font-medium text-gray-600;
    }

    th,
    td {
      @apply px-2 py-1 border-b;
    }
  }
}

.xs {
  .animal-tabs-text {
    @apply block mr-0;
  }
}
</style>
