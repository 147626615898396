<template>
  <component :is="tag" :class="{ 'opacity-50': loading || disabled }" :disabled="loading || disabled" @click="clickButton()">
    <span v-if="loading">Loading...</span>
    <slot v-else />
  </component>
</template>

<script>
export default {
  name: 'UtilButton',
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    tag: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    clickButton() {
      if (this.loading) return
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped></style>
