<template>
  <div ref="embed" class="embed" :style="style">
    <resize-observer @notify="setHeight" />
    <slot />
  </div>
</template>

<script>
import { ResizeObserver } from 'vue-resize'

export default {
  name: 'AppEmbed',
  components: {
    ResizeObserver
  },

  data() {
    return {
      height: 0,
      width: 0
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.setHeight({ width: this.$refs.embed.clientWidth, height: this.$refs.embed.clientHeight })
    })
    console.log(window.parent)
  },

  destroyed() {},

  computed: {
    style() {
      let style = ''
      let color = '#FFFFFF'
      if (this.$route.query.background) {
        color = this.$route.query.background
      }
      style += `background:${color};`
      return style
    }
  },

  methods: {
    setHeight({ width, height }) {
      if (height < 480) height = 480
      if (height !== this.height || width !== this.width) {
        this.width = width
        this.height = height
        window.parent.postMessage({ eventType: 'resize', width: width, height: height }, '*')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.embed {
  @apply relative min-h-screen;
}
</style>
